import { AuthenticatedUserProfileDTO, AuthenticatedUserTokenDTO } from 'api/account/dto/AuthenticatedUserDTO';

export const SET_AUTHENTICATION = 'SET_AUTHENTICATION';
export const SET_PROFILE = 'SET_PROFILE';

export type Action = SetAuthenticationAction | SetProfileAction;

export interface SetAuthenticationAction {
	type: typeof SET_AUTHENTICATION;
	token: AuthenticatedUserTokenDTO | null;
	profile: AuthenticatedUserProfileDTO | null;
}

export interface SetProfileAction {
	type: typeof SET_PROFILE;
	profile: AuthenticatedUserProfileDTO | null;
}

export interface AuthenticationState {
	isAuthenticated: boolean;
	token: AuthenticatedUserTokenDTO | null;
	profile: AuthenticatedUserProfileDTO | null;
}
