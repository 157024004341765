import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface BreadcrumbPath {
	name: string;
	url: string;
}

interface Props {
	title: string;
	breadcrumbs: BreadcrumbPath[];
	children?: ReactNode | undefined;
}

const PageHeader = ({ title, breadcrumbs, children }: Props) => {
	return (
		<div className="page-header">
			<div className="page-block">
				<div className="row align-items-center">
					<div className="col">
						<div className="page-header-title">
							<h5 className="m-b-10">{title}</h5>
						</div>
						<ul className="breadcrumb">
							{breadcrumbs.map((breadcrumb, i) => (
								<li key={i} className="breadcrumb-item">
									<Link to={breadcrumb.url}>{breadcrumb.name}</Link>
								</li>
							))}
						</ul>
					</div>
					{children && (
						<div className="col-auto">
							<div>{children}</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default PageHeader;
