import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { initAuthentication } from './store/authentication/action';

type Props = {
	children: React.ReactElement | React.ReactElement[];
};

const Authentication: React.FC<Props> = ({ children }: Props) => {
	const dispatch = useDispatch();
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		dispatch(initAuthentication(() => setLoaded(true)) as any);
	});

	return loaded ? <React.Fragment>{children}</React.Fragment> : null;
};

export default Authentication;
