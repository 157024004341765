import styled from 'styled-components';

export const Container = styled.div`
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	background-color: ${({ theme }) => theme.colors.white};
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 600;
`;
