import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { StoreState } from 'store/types';

type Props = {
	children: React.ReactElement;
};

const ProtectedRoute = ({ children }: Props) => {
	const isAuthenticated = useSelector<StoreState, boolean>((state) => state.authentication.isAuthenticated);
	if (!isAuthenticated) {
		return (
			<Navigate
				to={{
					pathname: '/authenticate',
				}}
				replace={true}
			/>
		);
	}

	return children;
};

export default ProtectedRoute;
