import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const usePageTitle = (title: string, retainOnUnmount = false) => {
	const defaultTitle = useRef(document.title);
	const { t } = useTranslation();

	useEffect(() => {
		document.title = (title ? title + ' - ' : '') + t('app.name');
	}, [title]);

	useEffect(() => {
		return () => {
			if (!retainOnUnmount) {
				document.title = defaultTitle.current;
			}
		};
	}, []);
};

export default usePageTitle;
