import { PagedList } from 'api/common/models/PagedList';
import Http from 'common/services/Http';
import { CreateUserViewModel } from './viewModels/CreateUserViewModel';
import { GetUserDTO } from './dtos/GetUserDTO';
import { GetUsersListDTO } from './dtos/GetUsersListDTO';
import { GetUsersListViewModel } from './viewModels/GetUsersListViewModel';
import { UpdateUserViewModel } from './viewModels/UpdateUserViewModel';

class UsersService {
	public create(model: CreateUserViewModel) {
		return Http.post<string>('users', model);
	}

	public update(model: UpdateUserViewModel) {
		return Http.put<string>('users', model);
	}

	public delete(id: string) {
		return Http.delete(`users/${id}`);
	}

	public get(id: string) {
		return Http.get<GetUserDTO>(`users/${id}`);
	}

	public getList(criteria: GetUsersListViewModel) {
		return Http.get<PagedList<GetUsersListDTO>>(`users`, criteria);
	}
}

export default new UsersService();
