import LoadingPage from 'common/components/loading/LoadingPage';
import ProtectedRoute from 'common/routes/ProtectedRoute';
import Http from 'common/services/Http';
import AuthenticatedLayout from 'layouts/authenticatedLayout/AuthenticatedLayout';
import RecoverPasswordPage from 'pages/authentication/RecoverPasswordPage';
import UserPage from 'pages/users/user/UserPage';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes, useNavigate } from 'react-router-dom';

const AuthenticationPage = React.lazy(() => import('pages/authentication/AuthenticationPage'));
const PatientsListPage = React.lazy(() => import('pages/patients/list/PatientsListPage'));
const PatientPage = React.lazy(() => import('pages/patients/patient/PatientPage'));
const ExamPage = React.lazy(() => import('pages/exams/exam/ExamPage'));
const UsersListPage = React.lazy(() => import('pages/users/list/UsersListPage'));
const NotFoundPage = React.lazy(() => import('pages/error/NotFoundPage'));
const ForbiddenPage = React.lazy(() => import('pages/error/ForbiddenPage'));

const InjectHistoryInAxios = () => {
	const navigation = useNavigate();

	useEffect(() => {
		Http.setHistory(navigation);
	}, [navigation]);

	return null;
};
/*
<ProtectedRoute>
	<AuthenticatedLayout>
		<Routes>
			<Route
				path="/thermal"
				element={
					<PolicyRoute policies={[Policy.WRITE_PATIENTS, Policy.READ_PATIENTS]}>
						<ThermalPage />
					</PolicyRoute>
				}
			/>
			<Route path="*">
				<Navigate to="/404" />
			</Route>
		</Routes>
	</AuthenticatedLayout>
</ProtectedRoute>;*/

const DefaultRouter = () => (
	<Router>
		<InjectHistoryInAxios />
		<React.Suspense fallback={<LoadingPage />}>
			<Routes>
				<Route path="/authenticate" element={<AuthenticationPage />} />
				<Route path="/recover-password" element={<RecoverPasswordPage />} />
				<Route path="/404" element={<NotFoundPage />} />
				<Route path="/403" element={<ForbiddenPage />} />
				<Route
					path="/*"
					element={
						<ProtectedRoute>
							<AuthenticatedLayout>
								<Routes>
									<Route path="/" element={<PatientsListPage />} />
									<Route path="/patients" element={<PatientsListPage />} />
									<Route path="/patients/:patientId/exams/:type/:id?" element={<ExamPage />} />
									<Route path="/patients/:type/:id?" element={<PatientPage />} />
									<Route path="/users" element={<UsersListPage />} />
									<Route path="/users/:type/:id?" element={<UserPage />} />
									<Route path="*" element={<Navigate to="/404" />} />
								</Routes>
							</AuthenticatedLayout>
						</ProtectedRoute>
					}
				></Route>
			</Routes>
		</React.Suspense>
	</Router>
);

export default DefaultRouter;
