import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { API_BASE_URL, STORAGE } from 'Config';
import dayjs from 'dayjs';
import qs from 'qs';
import { logout } from 'store/authentication/action';
import store from 'store/store';
import Storage from 'common/services/Storage';
import { AuthenticatedUserTokenDTO } from 'api/account/dto/AuthenticatedUserDTO';
import CustomFile from 'common/models/CustomFile';

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?$/;

function isIsoDateString(value: any): boolean {
	return value && typeof value === 'string' && isoDateFormat.test(value);
}

function handleDates(body: any) {
	if (body === null || body === undefined || typeof body !== 'object') return body;

	for (const key of Object.keys(body)) {
		const value = body[key];
		if (isIsoDateString(value)) body[key] = dayjs(value).toDate();
		else if (typeof value === 'object') handleDates(value);
	}
}

const axiosConfig: AxiosRequestConfig = {
	responseType: 'json',
	// withCredentials: true,
};

const http = axios.create(axiosConfig);

class Http {
	private history: any;

	constructor() {
		this.initInterceptors();
	}

	public setHistory(history: any) {
		this.history = history;
	}

	private initInterceptors() {
		// Request
		http.interceptors.request.use(
			async (config: AxiosRequestConfig) => {
				config.baseURL = API_BASE_URL;
				config.headers = await this.getHeaders();
				return config;
			},
			(error: AxiosError) => error,
		);

		// Response
		http.interceptors.response.use(
			async (response: AxiosResponse): Promise<any> => {
				const data = response.data;
				handleDates(data);
				return data;
			},
			async (error: AxiosError) => {
				if (error.response) {
					if (error.response.status === 401) {
						store.dispatch(logout() as any);
					} else if (error.response.status === 403) {
						this.history.push('/403');
					}
				}

				return Promise.reject(error);
			},
		);
	}

	public async post<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<T> {
		return http.post<any, T>(url, data, config).catch((error: any) => this.onError(error));
	}

	public async postWithFiles<T>(
		url: string,
		data: any,
		files: CustomFile[],
		config?: AxiosRequestConfig,
	): Promise<T> {
		const formData = this.prepareFormData(data, files);

		return http
			.post<any, T>(url, formData, {
				...(config || {}),
				headers: {
					'content-type': 'multipart/form-data',
				},
			})
			.catch((error: any) => this.onError(error));
	}

	public async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
		return http.delete<any, T>(url, config).catch((error: any) => this.onError(error));
	}

	public async put<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<T> {
		return http.put<any, T>(url, data, config).catch((error: any) => this.onError(error));
	}

	public async putWithFiles<T>(url: string, data: any, files: CustomFile[], config?: AxiosRequestConfig): Promise<T> {
		const formData = this.prepareFormData(data, files);

		return http
			.put<any, T>(url, formData, {
				...(config || {}),
				headers: {
					'content-type': 'multipart/form-data',
				},
			})
			.catch((error: any) => this.onError(error));
	}

	private prepareFormData(data: any, files: CustomFile[]) {
		const formData = new FormData();

		for (const file of files) {
			formData.append(`${file.containerName ? file.containerName : 'files[]'}`, file);
		}

		formData.append('model', JSON.stringify(data));

		return formData;
	}

	public async get<T>(url: string, params: any = null, config?: AxiosRequestConfig<any> | undefined): Promise<T> {
		if (params) {
			url = `${url}?${qs.stringify(params)}`;
		}
		return http.get<any, T>(url, config).catch((error: any) => this.onError(error));
	}

	private getHeaders = async (): Promise<AxiosRequestHeaders> => {
		const profile = Storage.getObject<AuthenticatedUserTokenDTO | null>(STORAGE.AUTH_USER_TOKEN);

		const headers: AxiosRequestHeaders = {
			Accept: 'application/json, text/plain, */*',
			'Content-Type': 'application/json; charset=utf-8',
		};

		if (profile) {
			headers.Authorization = `${profile.tokenType} ${profile.token}`;
		}

		return headers;
	};

	private onError(error: any) {
		// TODO: implement logger?
		return Promise.reject(error);
	}
}

export default new Http();
