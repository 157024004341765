import { Action, LayoutState, TOGGLE_SIDEBAR } from './type';

const initialState: LayoutState = {
	sideIsOpen: true,
};

function rootReducer(state: LayoutState = initialState, action: Action): LayoutState {
	switch (action.type) {
		case TOGGLE_SIDEBAR:
			return {
				...state,
				sideIsOpen: !state.sideIsOpen,
			};

		default:
			return state;
	}
}

export default rootReducer;
