import LogoDark from 'assets/img/logo-dark.svg';
import { useTranslation } from 'react-i18next';
import { FaUser, FaUserInjured } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Sidebar = () => {
	const { t } = useTranslation();
	return (
		<nav className="pc-sidebar">
			<div className="navbar-wrapper">
				<div className="m-header">
					<Link to="/" className="b-brand text-primary">
						<img src={LogoDark} className="img-fluid logo-lg" alt="logo" />
					</Link>
				</div>
				<div className="navbar-content">
					<ul className="pc-navbar">
						{/* Patients */}
						<li className="pc-item pc-caption">
							<label>{t('menu.patients')}</label>
							<i className="ti ti-news"></i>
						</li>
						<li className="pc-item">
							<Link to="/patients" className="pc-link">
								<FaUserInjured className="pc-icon" />
								<span className="pc-mtext">{t('menu.patients')}</span>
							</Link>
						</li>

						{/* Management */}
						<li className="pc-item pc-caption">
							<label>{t('menu.management')}</label>
						</li>
						<li className="pc-item">
							<Link to="/users" className="pc-link">
								<FaUser className="pc-icon" />
								<span className="pc-mtext">{t('menu.users')}</span>
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default Sidebar;
