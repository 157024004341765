import { LogLevel } from 'api/logs/enums/LogLevel';

/**
 * App
 */
export const APP_VERSION = process.env.REACT_APP_VERSION || '';

/**
 * Languages
 */
export const DEFAULT_LANGUAGE = 'pt';
export const DEFAULT_NS = 'translations';

/**
 * API
 */
export const API_BASE_URL = process.env.REACT_APP_API_URL;

/**
 * Storage
 */
export const STORAGE_KEY = 'cQfTjWnZr4u7x!A%D*G-KaNdRgUkXp2s';
export const STORAGE = {
	CURRENT_LOCALE: 'CURRENT_LOCALE',
	AUTH_USER_TOKEN: 'AUTH_USER_TOKEN',
	AUTH_USER_PROFILE: 'AUTH_USER_PROFILE',
};

/**
 * Logger
 */
const getLoggerLogLevel = (logLevelStr: string | undefined) => {
	if (!logLevelStr || !Object.keys(LogLevel).includes(logLevelStr)) {
		return null;
	}
	return LogLevel[logLevelStr as keyof typeof LogLevel];
};

export const LOGGER_PRINT_TO_CONSOLE =
	process.env.REACT_APP_LOGGER_PRINT_TO_CONSOLE !== undefined ? process.env.REACT_APP_LOGGER_PRINT_TO_CONSOLE : true;
export const LOGGER_PRINT_TO_CONSOLE_LEVEL =
	getLoggerLogLevel(process.env.REACT_APP_LOGGER_PRINT_TO_CONSOLE_LEVEL) || LogLevel.Information;
export const LOGGER_SEND_TO_SERVER =
	process.env.REACT_APP_LOGGER_SEND_TO_SERVER !== undefined ? process.env.REACT_APP_LOGGER_SEND_TO_SERVER : false;
export const LOGGER_SEND_TO_SERVER_LEVEL =
	getLoggerLogLevel(process.env.REACT_APP_LOGGER_SEND_TO_SERVER_LEVEL) || LogLevel.Error;

/**
 * DATE FORMAT
 */
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const DATEPICKER_DATE_FORMAT = 'dd/MM/yyyy';
export const DATEPICKER_DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm';
export const DATEPICKER_TIME_FORMAT = 'HH:mm';
export const DATE_TIME_SECONDS_FORMAT = 'DD/MM/YYYY HH:mm:ss';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const TIME_FORMAT = 'HH:mm';

/**
 * Pagination
 */
export const DEFAULT_PAGINATION_ITEMS_PER_PAGE = 10;

/**
 * MIMETYPES
 */
export const MIMETYPES_SVG = 'image/svg, image/svg+xml';
export const MIMETYPES_IMAGES = 'image/png, image/gif, image/jpeg';
export const MIMETYPES_PDF = 'application/pdf';
