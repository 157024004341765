import i18n, { InitOptions, LanguageDetectorAsyncModule } from 'i18next';
import { initReactI18next } from 'react-i18next';
import pt from 'assets/i18n/pt.json';
import { DEFAULT_LANGUAGE, STORAGE, DEFAULT_NS } from '../../Config';
import Storage from './Storage';

const languageDetector: LanguageDetectorAsyncModule = {
	type: 'languageDetector',
	async: true,
	detect: (callback: (lng: string) => void) => {
		const language = Storage.get(STORAGE.CURRENT_LOCALE);
		return callback(language || DEFAULT_LANGUAGE);
	},
	init: () => undefined,
	cacheUserLanguage: (language: string) => {
		Storage.set(STORAGE.CURRENT_LOCALE, language);
	},
};

const config: InitOptions = {
	fallbackLng: DEFAULT_LANGUAGE,
	debug: false,
	initImmediate: false,
	load: 'languageOnly',
	ns: [DEFAULT_NS],
	defaultNS: DEFAULT_NS,
	interpolation: {
		escapeValue: false,
	},
	react: {
		useSuspense: true,
	},
	resources: {
		pt: {
			[DEFAULT_NS]: pt,
		},
	},
};

i18n.use(languageDetector).use(initReactI18next).init(config);

export default i18n;
