import { toast, TypeOptions } from 'react-toastify';

class Toast {
	public error(message: string) {
		this.show(message, 'error');
	}

	public info(message: string) {
		this.show(message, 'info');
	}

	public warning(message: string) {
		this.show(message, 'warning');
	}

	public success(message: string) {
		this.show(message, 'success');
	}

	private show(message: string, type: TypeOptions) {
		toast(message, {
			type,
		});
	}
}

export default new Toast();
