// import { useTranslation } from 'react-i18next';
// import { FaCalendar, FaChartLine, FaCog, FaUser, FaUserMd, FaCogs } from 'react-icons/fa';
// import { Policy } from 'api/common/enums/Policy';
import React from 'react';
import Sidebar from './sidebar/SideBar';
import Navbar from './navbar/Navbar';
import Footer from './footer/Footer';

// import { useSelector } from 'react-redux';
// import { StoreState } from 'store/types';
// import { AuthenticatedUserProfileDTO } from 'api/account/dto/AuthenticatedUserDTO';
// import AccountService from 'api/account/AccountService';

// type MenuItem = {
// 	name: string;
// 	url: string;
// 	icon: ReactElement;
// 	show?: boolean;
// 	children?: MenuItem[];
// };

type Props = {
	children: React.ReactElement | React.ReactElement[];
};

const AuthenticatedLayout = ({ children }: Props) => {
	// const { t, i18n } = useTranslation();
	// const isOpen = useSelector<StoreState, boolean>((state) => state.layout.sideIsOpen);
	// const userProfile = useSelector<StoreState, AuthenticatedUserProfileDTO | null>(
	// 	(state) => state.authentication.profile,
	// );
	// const userPolicies = userProfile?.policies || [];

	// const hasPolicies = useCallback(
	// 	(policies: Policy[]) => AccountService.hasPolicies(userPolicies, policies, 'OR'),
	// 	[userPolicies],
	// );

	// const MENU: MenuItem[] = useMemo(
	// 	() => [
	// 		{
	// 			name: t('menu.dashboard'),
	// 			url: '/',
	// 			icon: <FaChartLine />,
	// 		},
	// 		{
	// 			name: t('menu.patients'),
	// 			url: '/patients',
	// 			icon: <FaUser />,
	// 			show: hasPolicies([Policy.WRITE_PATIENTS, Policy.READ_PATIENTS]),
	// 		},
	// 		{
	// 			name: t('menu.appointments'),
	// 			url: '/appointments',
	// 			icon: <FaCalendar />,
	// 			show: hasPolicies([Policy.WRITE_APPOINTMENTS, Policy.READ_APPOINTMENTS]),
	// 		},
	// 		{
	// 			name: t('menu.users'),
	// 			url: '/users',
	// 			icon: <FaUserMd />,
	// 			show: hasPolicies([Policy.WRITE_USERS, Policy.READ_USERS]),
	// 		},
	// 		{
	// 			name: t('menu.settings'),
	// 			url: '/settings',
	// 			icon: <FaCogs />,
	// 			children: [
	// 				{
	// 					name: t('menu.appointments_types'),
	// 					url: '/settings/appointments-types',
	// 					icon: <FaCog />,
	// 					show: hasPolicies([Policy.READ_APPOINTMENTS_TYPES, Policy.WRITE_APPOINTMENTS_TYPES]),
	// 				},
	// 			],
	// 		},
	// 	],
	// 	[hasPolicies, i18n.language],
	// );

	// return (
	// 	<div id="wrapper">
	// 		{/* <Layout.Sider trigger={null} collapsible collapsed={!isOpen}>
	// 			<Sidebar items={MENU} />
	// 		</Layout.Sider>
	// 		<Layout>
	// 			<LayoutHeader>
	// 				<Navbar />
	// 			</LayoutHeader> */}
	// 		{/* <LayoutContent> */}
	// 		<Sidebar />
	// 		<div id="content-wrapper" className="d-flex flex-column">
	// 			<div id="content">
	// 				<Navbar />
	// 				<div className="container-fluid">{children}</div>
	// 			</div>
	// 		</div>
	// 		{/* <Content>{children}</Content> */}
	// 		{/* </LayoutContent> */}
	// 		{/* <LayoutFooter>
	// 				<Footer />
	// 			</LayoutFooter>
	// 		</Layout> */}
	// 	</div>
	// );
	return (
		<div>
			<Sidebar />

			<Navbar />

			<div className="pc-container">
				<div className="pc-content">{children}</div>
			</div>

			<Footer />
		</div>
	);
};

export default AuthenticatedLayout;
