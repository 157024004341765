export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

export type Action = ToggleSidebarAction;

export interface ToggleSidebarAction {
	type: typeof TOGGLE_SIDEBAR;
}

export interface LayoutState {
	sideIsOpen: boolean;
}
