import usePageTitle from 'common/hooks/usePageTitle';
import PageHeader from 'layouts/authenticatedLayout/pageHeader/PageHeader';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { UpdateUserViewModel } from 'api/users/viewModels/UpdateUserViewModel';
import { CreateUserViewModel } from 'api/users/viewModels/CreateUserViewModel';
import { useEffect, useState } from 'react';
import LoadingPage from 'common/components/loading/LoadingPage';
import UsersService from 'api/users/UsersService';
import Logger from 'common/services/Logger';
import { LogType } from 'api/logs/enums/LogType';
import Toast from 'common/services/Toast';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { Button } from 'react-bootstrap';

type PageType = 'new' | 'edit' | 'details';

type FormModel = CreateUserViewModel & UpdateUserViewModel;

type UserPageParams = {
	type: PageType;
	id?: string;
};

const UserPage = () => {
	const { t } = useTranslation();
	const { type, id } = useParams<UserPageParams>();
	const pageTitle =
		type === 'new'
			? t('users.user.new_page_title')
			: type === 'edit'
			? t('users.user.edit_page_title')
			: t('users.user.page_title');

	usePageTitle(pageTitle);
	const navigate = useNavigate();
	const isDetails = type === 'details';
	const form = useForm<FormModel>();
	const [isLoading, setIsLoading] = useState(true);
	const [showRemoveQuestion, setShowRemoveQuestion] = useState(false);
	const errors = form.formState.errors;

	const getData = async () => {
		try {
			if (id) {
				setIsLoading(true);
				const user = await UsersService.get(id);
				console.log('user', user);
				form.reset(user);
			}

			setIsLoading(false);
		} catch (error) {
			Logger.error(LogType.PATIENTS, `error loading user data`);
			Toast.error(t('common.errors.load_data'));
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getData();
	}, [id, type]);

	if (isLoading) {
		return <LoadingPage />;
	}

	const onSubmit: SubmitHandler<FormModel> = async (data) => {
		try {
			setIsLoading(true);

			const tempModel: FormModel = { ...data };

			if (type === 'edit') {
				await UsersService.update(tempModel);
				navigate(`/users/details/${id}`);
			} else {
				const newId = await UsersService.create(tempModel);
				navigate(`/users/details/${newId}`);
			}

			Toast.success(t('common.success.operation'));

			setIsLoading(false);
		} catch (error) {
			if ((error as any)?.response?.data?.errorCode === 'EMAIL_EXISTS') {
				Toast.warning(t('users.user.email_Exists'));
			} else {
				Logger.error(LogType.PATIENTS, `error creating user`);
				Toast.error(t('common.errors.operation'));
			}

			setIsLoading(false);
		}
	};

	const removeUser = () => {
		if (!id) {
			return;
		}

		setShowRemoveQuestion(true);
	};

	const onRemoveUser = async () => {
		if (!id) {
			return;
		}

		setShowRemoveQuestion(false);
		setIsLoading(true);

		try {
			await UsersService.delete(id);
			Toast.success(t('common.success.delete_data'));
			navigate('/users');
		} catch (error) {
			Logger.error(LogType.PATIENTS, `error deleting user`);
			Toast.error(t('common.errors.operation'));
		}

		setIsLoading(false);
	};

	const onGoBack = () => {
		if (type === 'edit') {
			navigate('/users/details /' + id);
		} else {
			navigate('/users');
		}
	};

	return (
		<form onSubmit={form.handleSubmit(onSubmit)}>
			<PageHeader
				title={pageTitle}
				breadcrumbs={[
					{ name: t('users.list.page_title'), url: '/users' },
					type === 'new'
						? { name: t('users.user.new_page_title'), url: '/users/new' }
						: type === 'edit'
						? { name: t('users.user.edit_page_title'), url: '/users/edit/' + id }
						: { name: t('users.user.page_title'), url: '/users/details/' + id },
				]}
			>
				<Button variant="light" className="me-2" onClick={onGoBack}>
					{t('common.go_back_button')}
				</Button>
				{isDetails && (
					<Button variant="danger" className="me-2" onClick={removeUser}>
						{t('common.remove_button')}
					</Button>
				)}
				{isDetails && (
					<Link to={'/users/edit/' + id} className="btn btn-primary">
						{t('common.edit_button')}
					</Link>
				)}
				{!isDetails && (
					<Button type="submit" variant="success">
						{t('common.save_button')}
					</Button>
				)}
			</PageHeader>

			<div className="card">
				<div className="card-body">
					<div className="row">
						<div className="col-12">
							<div className="form-group mb-3 has-validation">
								<label className="form-label">{t('users.user.name')}*</label>
								<input
									type="text"
									className="form-control"
									placeholder={t('users.user.name')}
									disabled={isDetails}
									maxLength={255}
									{...form.register('name', { required: true })}
								/>
								{errors.name && (
									<div className="invalid-feedback">{t('common.errors.required_field')}</div>
								)}
							</div>
						</div>
						<div className="col-8">
							<div className="form-group mb-3 has-validation">
								<label className="form-label">{t('users.user.email')}*</label>
								<input
									type="email"
									className="form-control"
									placeholder={t('users.user.email')}
									disabled={isDetails}
									maxLength={255}
									{...form.register('email', { required: true })}
								/>
								{errors.email && (
									<div className="invalid-feedback">{t('common.errors.required_field')}</div>
								)}
							</div>
						</div>
						{type !== 'details' && (
							<div className="col-4">
								<div className="form-group mb-3 has-validation">
									<label className="form-label">
										{t('users.user.password')}
										{type === 'new' ? '*' : ''}
									</label>
									<input
										type="password"
										className="form-control"
										placeholder={t('users.user.password')}
										disabled={isDetails}
										maxLength={255}
										minLength={8}
										{...form.register('password', { required: type === 'new' })}
									/>
									{type === 'edit' && (
										<div className="text-secondary">{t('users.user.password_info')}</div>
									)}
									{errors.password && (
										<div className="invalid-feedback">{t('common.errors.required_field')}</div>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>

			<QuestionYesNo
				isVisible={showRemoveQuestion}
				title={t('common.remove_question_title')}
				message={t('common.remove_question_message', { item: form.getValues('name') })}
				onNo={() => setShowRemoveQuestion(false)}
				onYes={onRemoveUser}
			/>
		</form>
	);
};

export default UserPage;
