import Loading from './Loading';
import { Container } from './LoadingPage.style';

const LoadingPage = () => {
	return (
		<Container>
			<Loading />
		</Container>
	);
};

export default LoadingPage;
