import { AuthenticatedUserProfileDTO } from 'api/account/dto/AuthenticatedUserDTO';
import DefaultAvatar from 'assets/img/default_avatar.png';
import { useTranslation } from 'react-i18next';
import { FaSignOutAlt } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { logout } from 'store/authentication/action';
import { StoreState } from 'store/types';

const Navbar = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const userProfile = useSelector<StoreState, AuthenticatedUserProfileDTO | null>(
		(state) => state.authentication.profile,
	);

	const onLogout = () => {
		dispatch(logout() as any);
	};

	return (
		<header className="pc-header">
			<div className="header-wrapper">
				{/* <div className="me-auto pc-mob-drp">
					<ul className="list-unstyled">
						<li className="pc-h-item pc-sidebar-collapse">
							<a href="#" className="pc-head-link ms-0" id="sidebar-hide">
								<i className="ti ti-menu-2"></i>
							</a>
						</li>
						<li className="pc-h-item pc-sidebar-popup">
							<a href="#" className="pc-head-link ms-0" id="mobile-collapse">
								<i className="ti ti-menu-2"></i>
							</a>
						</li>
						<li className="dropdown pc-h-item d-inline-flex d-md-none">
							<a
								className="pc-head-link dropdown-toggle arrow-none m-0"
								data-bs-toggle="dropdown"
								href="#"
								role="button"
								aria-haspopup="false"
								aria-expanded="false"
							>
								<i className="ti ti-search"></i>
							</a>
							<div className="dropdown-menu pc-h-dropdown drp-search">
								<form className="px-3">
									<div className="form-group mb-0 d-flex align-items-center">
										<i data-feather="search"></i>
										<input
											type="search"
											className="form-control border-0 shadow-none"
											placeholder="Search here. . ."
										/>
									</div>
								</form>
							</div>
						</li>
						<li className="pc-h-item d-none d-md-inline-flex">
							<form className="header-search">
								<i data-feather="search" className="icon-search"></i>
								<input type="search" className="form-control" placeholder="Search here. . ." />
							</form>
						</li>
					</ul>
				</div> */}
				<div className="ms-auto">
					<ul className="list-unstyled">
						<li className="pc-h-item">
							<a
								className="pc-head-link arrow-none me-0"
								href="#"
								role="button"
								aria-haspopup="false"
								aria-expanded="false"
								style={{ width: 'auto', padding: '0 0.5rem' }}
								onClick={onLogout}
							>
								<FaSignOutAlt fontSize={30} />
								<span>{t('menu.logout')}</span>
							</a>
						</li>
						<li className="pc-h-item header-user-profile">
							<a
								className="pc-head-link arrow-none me-0"
								href="#"
								role="button"
								aria-haspopup="false"
								aria-expanded="false"
							>
								<img src={DefaultAvatar} alt="user-image" className="user-avtar" />
								<span>{userProfile?.name}</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</header>
	);
};

export default Navbar;
