import { Action, AuthenticationState, SET_AUTHENTICATION, SET_PROFILE } from './type';

const initialState: AuthenticationState = {
	isAuthenticated: false,
	profile: null,
	token: null,
};

function rootReducer(state: AuthenticationState = initialState, action: Action): AuthenticationState {
	switch (action.type) {
		case SET_AUTHENTICATION:
			return {
				...state,
				isAuthenticated: action.profile ? true : false,
				profile: action.profile || null,
				token: action.token || null,
			};

		case SET_PROFILE:
			return {
				...state,
				profile: action.profile || null,
			};

		default:
			return state;
	}
}

export default rootReducer;
