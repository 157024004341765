import { useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import { ThemeContext } from 'styled-components';

const Loading = () => {
	const themeContext = useContext(ThemeContext);
	return <Spinner animation="border" color={themeContext.colors.primary} />;
};

export default Loading;
