import defaultTheme from 'common/theme/defaultTheme';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import i18n from './common/services/I18n';
import DefaultRouter from './Router';
import { Provider } from 'react-redux';
import store from './store/store';
import 'styles/styles.scss';
import Authentication from 'Authentication';
import { pt } from 'date-fns/locale/pt';
import { registerLocale } from 'react-datepicker';
registerLocale('pt', pt);
import { ToastContainer } from 'react-toastify';

const App = () => {
	return (
		<Provider store={store}>
			<I18nextProvider i18n={i18n}>
				<ThemeProvider theme={defaultTheme}>
					{/* <GlobalStyle /> */}
					<Authentication>
						<DefaultRouter />
					</Authentication>
					<ToastContainer />
				</ThemeProvider>
			</I18nextProvider>
		</Provider>
	);
};

export default App;
