import { Policy } from 'api/common/enums/Policy';
import CustomFile from 'common/models/CustomFile';
import Http from 'common/services/Http';
import AuthenticatedUserDTO from './dto/AuthenticatedUserDTO';
import { GetUserProfileDTO } from './dto/GetUserProfileDTO';
import AuthenticationViewModel from './viewModels/AuthenticationViewModel';
import { UpdateUserProfileViewModel } from './viewModels/UpdateUserProfileViewModel';
import RecoverPasswordViewModel from './viewModels/RecoverPasswordViewModel';
import FinishRecoverPasswordViewModel from './viewModels/FinishRecoverPasswordViewModel';

class AccountService {
	public update(model: UpdateUserProfileViewModel, photo: CustomFile | null) {
		return Http.putWithFiles<string>('account', model, photo ? [photo] : []);
	}

	public get() {
		return Http.get<GetUserProfileDTO>(`account`);
	}

	public authenticate(model: AuthenticationViewModel) {
		return Http.post<AuthenticatedUserDTO>('account/authenticate', model);
	}

	public recoverPassword(model: RecoverPasswordViewModel) {
		return Http.post('account/recover-password', model);
	}

	public finishRecoverPassword(model: FinishRecoverPasswordViewModel) {
		return Http.post('account/finish-recover-password', model);
	}

	public hasPolicies(userPolicies: string[], policies: Policy[], type: 'OR' | 'AND' = 'OR') {
		const allowed: boolean =
			type === 'AND'
				? policies.map((p) => userPolicies.find((up) => up === p)).filter((p) => !p).length === 0
					? true
					: false
				: policies.map((p) => userPolicies.find((up) => up === p)).filter((p) => p).length > 0
				? true
				: false;
		return allowed;
	}
}

export default new AccountService();
