const defaultTheme = {
	colors: {
		white: '#ffffff',
		black: '#000000',
		primary: '#000', // TODO:
		info: '#000', // TODO:
		danger: '#000', // TODO:
		success: '#000', // TODO:
		warning: '#000', // TODO:
	},
	page: {
		padding: '24px',
	},
	alert: {
		borderRadius: '6px',
	},
	navbar: {
		backgroundColor: '#ffffff',
		borderColor: '#e1e1e1',
		textColor: '#2c2069',
		item: {
			textColor: '#aba8b9',
			active: {
				textColor: '#2c2069',
			},
		},
	},
	divider: {
		backgroundColor: '#e1e1e1',
	},
	footer: {
		borderColor: '#d2dae2',
		textColor: '#0f053d',
	},
};

export default defaultTheme;

export type DefaultTheme = typeof defaultTheme;
