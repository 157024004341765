import usePageTitle from 'common/hooks/usePageTitle';
import { useTranslation } from 'react-i18next';
import LogoDark from 'assets/img/logo-dark.svg';
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StoreState } from 'store/types';
import AccountService from 'api/account/AccountService';
import { useState } from 'react';
import { LogType } from 'api/logs/enums/LogType';
import Logger from 'common/services/Logger';
import LoadingPage from 'common/components/loading/LoadingPage';
import Toast from 'common/services/Toast';
import { FaAngleLeft } from 'react-icons/fa';
import { SubmitHandler, useForm } from 'react-hook-form';

type RecoverFormModel = {
	email: string;
	password: string;
};

const RecoverPasswordPage = () => {
	const { t } = useTranslation();
	usePageTitle(t('recover_password.page_title'));
	const [loading, setLoading] = useState(false);
	const isAuthenticated = useSelector<StoreState, boolean>((state) => state.authentication.isAuthenticated);
	const form = useForm<RecoverFormModel>();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const resetToken = searchParams.get('token');
	const errors = form.formState.errors;

	const onSubmit: SubmitHandler<RecoverFormModel> = async (model) => {
		try {
			setLoading(true);

			if (resetToken) {
				await AccountService.finishRecoverPassword({ ...model, token: resetToken });
				Toast.success(t('recover_password.success_recover'));
			} else {
				await AccountService.recoverPassword(model);
				Toast.success(t('recover_password.success_email'));
			}

			navigate('/authenticate');

			setLoading(false);
		} catch (error) {
			Logger.error(LogType.RECOVER_PASSWORD, `error recovering password`, error);
			if (resetToken) {
				Toast.error(t('recover_password.error_recover'));
			} else {
				Toast.error(t('recover_password.error_email'));
			}

			setLoading(false);
		}
	};

	if (isAuthenticated) {
		return (
			<Navigate
				to={{
					pathname: '/',
				}}
				replace={true}
			/>
		);
	}

	if (loading) {
		return <LoadingPage />;
	}

	// const onEmailKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
	// 	if (e.key === 'Enter' && !resetToken) {
	// 		form.handleSubmit(onSubmit)();
	// 	}
	// };

	// const onPasswordKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
	// 	if (e.key === 'Enter') {
	// 		form.handleSubmit(onSubmit)();
	// 	}
	// };

	return (
		<form onSubmit={form.handleSubmit(onSubmit)}>
			<div className="auth-main">
				<div className="auth-wrapper v3">
					<div className="auth-form">
						<div className="auth-header">
							<a href="/">
								<img src={LogoDark} alt="logo" />
							</a>
						</div>
						<div className="card my-5">
							<div className="card-body">
								<div className="d-flex justify-content-between align-items-end mb-4">
									<h3 className="mb-0">
										<b>
											<Link to="/authenticate" style={{ color: 'var(--pc-heading-color)' }}>
												<FaAngleLeft style={{ marginRight: 10 }} />
											</Link>
											{t('recover_password.page_title')}
										</b>
									</h3>
								</div>
								<div className="d-flex justify-content-between align-items-end mb-4">
									<p className="mb-0">
										{resetToken
											? t('recover_password.information_change')
											: t('recover_password.information_email')}
									</p>
								</div>
								<div className="form-group mb-3">
									<label className="form-label" htmlFor="email">
										{t('recover_password.email')}
									</label>
									<input
										type="email"
										className="form-control"
										id="email"
										placeholder={t('recover_password.email')}
										maxLength={255}
										{...form.register('email', { required: true })}
										// onKeyDown={onEmailKeyDown}
									/>
									{errors.email && (
										<div className="invalid-feedback">{t('common.errors.required_field')}</div>
									)}
								</div>
								{resetToken && (
									<div className="form-group mb-3">
										<label className="form-label" htmlFor="password">
											{t('recover_password.new_password')}
										</label>
										<input
											type="password"
											className="form-control"
											id="password"
											placeholder={t('recover_password.new_password')}
											maxLength={255}
											minLength={8}
											{...form.register('password', { required: true })}
											// onKeyDown={onPasswordKeyDown}
										/>
										{errors.password && (
											<div className="invalid-feedback">{t('common.errors.required_field')}</div>
										)}
									</div>
								)}
								<div className="d-grid mt-4">
									<button type="submit" className="btn btn-primary">
										{resetToken
											? t('recover_password.change_button')
											: t('recover_password.send_button')}
									</button>
								</div>
							</div>
						</div>
						<div className="auth-footer row">
							<div className="col my-1">
								<p className="m-0">
									{t('recover_password.copyright')} ©{' '}
									<a href="/" target="_blank" rel="noreferrer">
										{t('app.name')}
									</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
};

export default RecoverPasswordPage;
