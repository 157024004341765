import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';

type Props = {
	title: string;
	message: string;
	isVisible: boolean;
	onYes: () => void;
	onNo: () => void;
};

const QuestionYesNo: React.FC<Props> = ({ title, message, isVisible, onYes, onNo }: Props) => {
	const { t } = useTranslation();

	return (
		<Modal show={isVisible} onHide={onNo}>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{message}</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={onNo}>
					{t('common.no')}
				</Button>
				<Button variant="primary" onClick={onYes}>
					{t('common.yes')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default QuestionYesNo;
