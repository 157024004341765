import { useTranslation } from 'react-i18next';

const Footer = () => {
	const { t } = useTranslation();

	return (
		<footer className="pc-footer">
			<div className="footer-wrapper container-fluid">
				<div className="row">
					<div className="col-sm my-1">
						<p className="m-0">{t('footer.copyright', { year: new Date().getFullYear() })}</p>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
