import { Dispatch } from 'redux';
import Storage from 'common/services/Storage';
import { STORAGE } from 'Config';
import { SET_AUTHENTICATION, SET_PROFILE } from './type';
import { AuthenticatedUserProfileDTO, AuthenticatedUserTokenDTO } from 'api/account/dto/AuthenticatedUserDTO';
import AccountService from 'api/account/AccountService';

export const initAuthentication = (cb: (rt: boolean) => void) => async (dispatch: Dispatch) => {
	try {
		const profile = Storage.getObject<AuthenticatedUserProfileDTO | null>(STORAGE.AUTH_USER_PROFILE);
		const token = Storage.getObject<AuthenticatedUserTokenDTO | null>(STORAGE.AUTH_USER_TOKEN);
		if (!profile || !token) {
			cb(false);
			return;
		}

		dispatch(setAuthentication(profile, token));

		cb(true);
	} catch (ex) {
		dispatch(logout() as any);
		cb(false);
	}
};

export const updateProfile = () => async (dispatch: Dispatch) => {
	const profile = Storage.getObject<AuthenticatedUserProfileDTO | null>(STORAGE.AUTH_USER_PROFILE);
	if (!profile) {
		return;
	}

	const newProfile = await AccountService.get();

	const profileToSave: AuthenticatedUserProfileDTO = {
		id: profile.id,
		roles: newProfile.roles,
		policies: newProfile.policies,
		photoUrl: newProfile.photo?.url || null,
		email: newProfile.email,
		name: newProfile.name,
	};

	Storage.setObject(STORAGE.AUTH_USER_PROFILE, profileToSave);

	dispatch(setProfile(profileToSave));
};

export const authenticateUser =
	(profile: AuthenticatedUserProfileDTO | null, token: AuthenticatedUserTokenDTO | null) =>
	async (dispatch: Dispatch) => {
		Storage.setObject(STORAGE.AUTH_USER_PROFILE, profile);
		Storage.setObject(STORAGE.AUTH_USER_TOKEN, token);

		dispatch(setAuthentication(profile, token));
	};

export const logout = () => async (dispatch: Dispatch) => {
	Storage.remove(STORAGE.AUTH_USER_TOKEN);
	Storage.remove(STORAGE.AUTH_USER_PROFILE);

	dispatch(setAuthentication(null, null));
};

export const setAuthentication = (
	profile: AuthenticatedUserProfileDTO | null,
	token: AuthenticatedUserTokenDTO | null,
) => ({
	type: SET_AUTHENTICATION,
	profile,
	token,
});

export const setProfile = (profile: AuthenticatedUserProfileDTO | null) => ({
	type: SET_PROFILE,
	profile,
});
