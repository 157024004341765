export enum LogType {
	OTHER = 'OTHER',
	AUTHENTICATION = 'AUTHENTICATION',
	RECOVER_PASSWORD = 'RECOVER_PASSWORD',
	APP_CRASH = 'APP_CRASH',
	PATIENTS = 'PATIENTS',
	EXAMS = 'EXAMS',
	USERS = 'USERS',
	THERMAL = 'THERMAL',
}
