import Http from 'common/services/Http';
import { CreateLogViewModel } from './viewModels/CreateLogViewModel';

class LogsService {
	public create(model: CreateLogViewModel) {
		return Http.post('logs', model);
	}
}

export default new LogsService();
